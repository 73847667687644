import React, { useState, useEffect } from 'react';
import Card from './Card';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Testimonials = (props) => {
    let reviews = props.reviews;
    const [index1, setIndex1] = useState(0);
    const [index2, setIndex2] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function leftShiftHandler() {
        setIndex1((index1 - 1 + reviews.length) % reviews.length);
        if (!isMobile) {
            setIndex2((index2 - 1 + reviews.length) % reviews.length);
        }
    }

    function rightShiftHandler() {
        setIndex1((index1 + 1) % reviews.length);
        if (!isMobile) {
            setIndex2((index2 + 1) % reviews.length);
        }
    }

    return (
        <div className='container bg-transparent d-flex flex-column align-items-center justify-center mt-5 p-5 transition-all duration-700 hover:shadow-xl rounded-md'>
            <div className={`d-flex ${isMobile ? 'card-container' : 'gap-5'}`}>
                <Card review={reviews[index1]} />
                {!isMobile && <Card review={reviews[index2]} />}
            </div>
            <div className='d-flex text-3xl mt-4 gap-3 text-primary font-bold bg-white'>
                <button 
                    onClick={leftShiftHandler}
                    className='cursor-pointer hover:text-secondary'>
                    <FiChevronLeft />
                </button>
                <button 
                    onClick={rightShiftHandler}
                    className='cursor-pointer hover:text-secondary'>
                    <FiChevronRight />
                </button>
            </div>
        </div>
    );
}

export default Testimonials;
