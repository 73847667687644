import React from "react";
import { Container } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./Services.css"; 

export const Services = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container size="md" pt={{ base: "3.125rem", md: "4.375rem" }}>
      <Slider {...settings}>
      <div>
          <h1 className="service-title">UI/UX DESIGN</h1>
        </div>
        <div>
          <h1 className="service-title"> WEBSITE</h1>
        </div>
        <div>
          <h1 className="service-title">APPlication</h1>
        </div>
       
        <div>
          <h1 className="service-title"> BRANDING </h1>
        </div>
      </Slider>
    </Container>
  );
};
