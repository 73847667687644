import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { NavBar } from "./NavBar";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projweb1 from "../assets/img/web1.JPG"
import projimg4 from "../assets/img/uiux2 (1).png"
import projimg5 from "../assets/img/uiux2 (2).png"
import projimg6 from "../assets/img/uiux2 (3).png"
import waliiftran from "../assets/img/waliif transportation.jpg"
import waliifcons from "../assets/img/waliif construction.jpg"
import breez from "../assets/img/breez.png"
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';


import TrackVisibility from 'react-on-screen';

export const Works = () => {

  const projects = [
    {
      title: "Uptime with medical theam",
      description: "UI/UX design",
      imgUrl: projImg2,
    },
    {
      title: "Hahu cloud",
      description: "UI/UX design",
      imgUrl: projimg5,
    },
    {
      title: "Uptime with medical theam",
      description: "UI/UX design",
      imgUrl: projImg2,
    },
    {
      title: "Hahu cloud",
      description: "UI/UX design",
      imgUrl: projimg5,
    },
    {
      title: "Uptime with medical theam",
      description: "UI/UX design",
      imgUrl: projImg2,
    },
    {
      title: "Hahu cloud",
      description: "UI/UX design",
      imgUrl: projimg5,
    },
    {
      title: "Uptime with medical theam",
      description: "UI/UX design",
      imgUrl: projImg2,
    },
    {
      title: "Hahu cloud",
      description: "UI/UX design",
      imgUrl: projimg5,
    },
    {
      title: "Uptime with medical theam",
      description: "UI/UX design",
      imgUrl: projImg2,
    },
    {
      title: "Hahu cloud",
      description: "UI/UX design",
      imgUrl: projimg5,
    },
    {
      title: "Uptime with medical theam",
      description: "UI/UX design",
      imgUrl: projImg2,
    },
    {
      title: "Hahu cloud",
      description: "UI/UX design",
      imgUrl: projimg5,
    },
    
  ];
  const projects1 = [
    {
      title: "EThio Food",
      description: "https://github.com/",
      imgUrl: projimg4,
    },
    {
      title: "ዘነዘና Coffee",
      description: "https://github.com/",
      imgUrl: projweb1,
    },
    {
      title: "EThio Food",
      description: "https://github.com/",
      imgUrl: projimg4,
    },
    {
      title: "ዘነዘና Coffee",
      description: "https://github.com/",
      imgUrl: projweb1,
    },
    {
      title: "EThio Food",
      description: "https://github.com/",
      imgUrl: projimg4,
    },
    {
      title: "ዘነዘና Coffee",
      description: "https://github.com/",
      imgUrl: projweb1,
    },
    {
      title: "EThio Food",
      description: "https://github.com/",
      imgUrl: projimg4,
    },
    {
      title: "ዘነዘና Coffee",
      description: "https://github.com/",
      imgUrl: projweb1,
    },
    {
      title: "EThio Food",
      description: "https://github.com/",
      imgUrl: projimg4,
    },
    {
      title: "ዘነዘና Coffee",
      description: "https://github.com/",
      imgUrl: projweb1,
    },
    {
      title: "EThio Food",
      description: "https://github.com/",
      imgUrl: projimg4,
    },
    {
      title: "ዘነዘና Coffee",
      description: "https://github.com/",
      imgUrl: projweb1,
    },
  ];
  const projects3 = [
    {
    title: "Waliif Construction Annual Report",
    description: "https://github.com/",
    imgUrl: waliifcons,
    },

    {
    title: "Waliif Transportation Annual Report",
    description: "https://github.com/",
    imgUrl: waliiftran,
    },
    {
      title: "Breez Coffee Branding",
      description: "https://github.com/",
      imgUrl: breez,
      },
   {
      title: "Breez Coffee Branding",
      description: "https://github.com/",
      imgUrl: breez,
      },
      {
        title: "Waliif Construction Annual Report",
        description: "https://github.com/",
        imgUrl: waliifcons,
        },
    
        {
        title: "Waliif Transportation Annual Report",
        description: "https://github.com/",
        imgUrl: waliiftran,
        },
        {
          title: "Breez Coffee Branding",
          description: "https://github.com/",
          imgUrl: breez,
          },
       {
          title: "Breez Coffee Branding",
          description: "https://github.com/",
          imgUrl: breez,
          },
          {
            title: "Waliif Construction Annual Report",
            description: "https://github.com/",
            imgUrl: waliifcons,
            },
        
            {
            title: "Waliif Transportation Annual Report",
            description: "https://github.com/",
            imgUrl: waliiftran,
            },
            {
              title: "Breez Coffee Branding",
              description: "https://github.com/",
              imgUrl: breez,
              },
           {
              title: "Breez Coffee Branding",
              description: "https://github.com/",
              imgUrl: breez,
              },
              {
                title: "Waliif Construction Annual Report",
                description: "https://github.com/",
                imgUrl: waliifcons,
                },
            
                {
                title: "Waliif Transportation Annual Report",
                description: "https://github.com/",
                imgUrl: waliiftran,
                },
                {
                  title: "Breez Coffee Branding",
                  description: "https://github.com/",
                  imgUrl: breez,
                  },
               {
                  title: "Breez Coffee Branding",
                  description: "https://github.com/",
                  imgUrl: breez,
                  },
      
     
  ];
  return (
    <section className="project" id="project">
       <NavBar></NavBar>
       <div className="space">
      <Container >
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""} >
                <h2>Our Works</h2>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">UI/UX Design</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Website Design</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Grphics Design</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>     
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projects1.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>     
                      </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          projects3.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>     
                      </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
               
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      </div>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
    
  )
}
