import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/perspective.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Link } from "react-bootstrap-icons";
import { Navigate, useNavigate } from "react-router-dom";


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(500 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [  "Web Development","web Design" ,"Mobile Design","Branding" ];
  const period = 1000;
  let navigate = useNavigate()
  const SeeMore = () =>{
    let path= '/contact'; 
    navigate(path)
  }

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      
      <div class="outer">

</div>
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <h2 className="bannertitile">NOTHING IS BIZARRE</h2>
            <h6>At PERSPECTIVE, we turn your ideas into reality, transforming the unconventional into extraordinary innovations. Specializing in web app branding, website development, and tech solutions, we bring your imagination to life.
</h6>
            <br></br><br></br>
            <button className="but" onClick={SeeMore}>Work With US </button>
          </Col>
   
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                   <img src={headerImg} alt="Header Img"/>
               
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
        
        {/* <Col xs={12} md={6} xl={7}>
        <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>{``} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "UI/UX Designer", "Web Designer", "Web Developer" ]'><span className="wrap">{text}</span></span></h1>
                <h6>Elevate Your Digital Presence: Where Innovation Meets Imagination Unleashing Seamless UI/UX Designs, Striking Graphics, and Next-Level Mobile Development.</h6>
                  <br></br><br></br><br></br>
                  <button className="but" onClick={SeeMore}>Work With US </button>
            
              </div>}
              
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                   <img src={headerImg} alt="Header Img"/>
               
                </div>}
            </TrackVisibility>
          </Col>
        </Col> */}
      </Container>
    </section>
  )
}
