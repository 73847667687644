import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import '../assets/css/Caffeinn_detail.css'; // You can create this CSS file for styling
import img1 from '../assets/img//caffein.png';
import img2 from '../assets/img//caffein2.png';
import img3 from '../assets/img//caffein3.png';
import img4 from '../assets/img//caffein4.png';
import img5 from '../assets/img//caffein5.png';
import img6 from '../assets/img//caffein6.png';
import img7 from '../assets/img//caffein7.png';

const Caffeinn_detail = () => {

  const originalColors = [
    '#ff5f45',
    '#0798ec',
    '#fc6c7c',
    '#435b71',
    'orange',
    'blue',
    'purple',
    'yellow'
  ];

  const fullpages = [
    {
    
      image: img1,
    },
    {
  
      image: img2,   
     },
    {
    
      image: img3,  
      }
      ,
      {
     
        image: img4,  
        } ,
        {
     
            image: img5,  
            } ,
            {
     
                image: img6,  
                } 
             
  ];

  const Menu = () => (
    <div
      className="menu"
      style={{
        position: 'fixed',
        top: 0,
        zIndex: 100
      }}
    ></div>
  );

  const handleLeave = (origin, destination, direction) => {
    console.log('onLeave', { origin, destination, direction });
    // You can do something with the scroll event here
  };

  return (
    <div className="Caffeinn_detail">
     
      <Menu />
      <ReactFullpage
        debug /* Debug logging */
        licenseKey={'YOUR_KEY_HERE'} // Get one from https://alvarotrigo.com/fullPage/pricing/
        navigation
        anchors={['firstPage', 'secondPage', 'thirdPage']}
        onLeave={handleLeave}
        sectionsColor={originalColors}
        render={({ fullpageApi }) => (
          <ReactFullpage.Wrapper>
            {fullpages.map(({ text, image }, index) => (
              <div key={index} className="section">
                <div className="slide" style={{ backgroundImage: `url(${image})` }}>
                  <h3>{text}</h3>
                </div>
              </div>
            ))}
          </ReactFullpage.Wrapper>
        )}
      />
    </div>
  );
};

export default Caffeinn_detail;