
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Navbar, Nav, Container } from "react-bootstrap";
// import { NavBar } from "./NavBar";
import { Banner } from "./Banner";
import { Skills } from "./Skills";
import { Services } from "./Services";
import { Projects } from "./Projects";
import { Contact } from "./Contact";
import { Footer } from "./Footer";
import { Works } from "./Works";
import { useState, useEffect } from "react";
import logo from '../assets/img/perspective.png';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";
// import Single from './Single';
import { Price } from './Price';
import { MailchimpForm } from './MailchimpForm';
import Draft from './Draft';
import ImageSlider from './Imageslider';
import { Client } from './Client';
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";

 
export const Home = () => {
  const [isopen, setisopen] = useState(false);
  const toggle = () => {
    setisopen(!isopen);
  };
    return ( 
        <div>
           
         <Navbar toggle={toggle} />
         <Sidebar isopen={isopen} toggle={toggle} />
           {/* <NavBar></NavBar> */}
           <Draft></Draft>
         
           <Banner></Banner>
           <Services></Services>
        
           {/* <Skills></Skills>  */}
      

           <Projects></Projects> 
                {/* <Client></Client> */}
           {/* <ImageSlider></ImageSlider> */}
           {/* <Price></Price> */}
           <Contact></Contact> 
           {/* <MailchimpForm></MailchimpForm> */}
           <Footer></Footer>
           
           
            
        </div>
    ) 
}
