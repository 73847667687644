import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Container from '@mui/material/Container';

// import pdf from '../assets/img/ui/ethio food.jpg'
 

 import pdf from '../assets/img/ui/ethio food.png'


import { NavBar } from './NavBar';
import { Footer } from './Footer';
export const Food = () => {
// export default function Speaklife () {
  return (
    <div>
    <NavBar></NavBar>
    <Container maxWidth="lg">
   <div className='speaklife'>
    <img src={pdf} />   
    </div>
    </Container>
    <Footer></Footer>
    </div>
  );
}