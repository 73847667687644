import logo from './logo.svg';
import React, { useState } from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { About } from "./components/About";
import { Home } from "./components/Home";
import { Price } from "./components/Price";
import { Works } from "./components/Works";

// import { Single } from "./components/Single"
import {Zenezena} from "./components/Zenezena";
import { Bike } from "./components/Bike"; 

//import SinglePost from './components/SinglePost';
import { Qasil } from './components/Qasil';
import {Adey} from './components/Adey';
import {Speaklife} from './components/Speaklife';
import { Caffe } from './components/Caffe';
import { Breez } from './components/Breez';
import { Food } from './components/Food';
import { Eloan } from './components/Eloan';
import {MailchimpForm} from './components/MailchimpForm';
import { Contactmain } from './components/Contactmain';
import { Comingsoon } from './components/Comingsoon';
import { Ikram } from './components/Ikram';
import { Shop } from './components/Shop';
import {Yenacon} from './components/Yenacon';
import { Hayloga } from './components/Hayloga'
import { Poster } from './components/Poster';
import Caffeinn_detail from './components/Caffeinn_detail';
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import AnimatedCursor from "react-animated-cursor"
function App() {
  const [isopen, setisopen] = useState(false);
  const toggle = () => {
    setisopen(!isopen);
  };
  return (
<div className="App">
<AnimatedCursor
          color="255,255,255"
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={2}
          outerAlpha={1}
          hasBlendMode={true}
          outerStyle={{
            mixBlendMode: 'exclusion'
          }}
          innerStyle={{
            backgroundColor: 'var(--cursor-color)',
            mixBlendMode: 'exclusion'
          }}
        />
<Router>
<Navbar toggle={toggle} />
<Sidebar isopen={isopen} toggle={toggle} />
<Routes>
  <Route exact path="/" element={<Home />}></Route>
</Routes>
{/* <Routes>
  <Route  path="/NavBar" element={<NavBar></NavBar>}></Route>
</Routes> */}
<Routes>
  <Route  path="/Banner" element={<Banner></Banner>}></Route>
</Routes>
<Routes>
  <Route  path="/Skills" element={<Skills></Skills>}></Route>
</Routes>
<Routes>
  <Route  path="/Projects" element={<Projects></Projects>}></Route>
</Routes>
<Routes>
  <Route  path="/Contact" element={<Contact></Contact>}></Route>
</Routes>
<Routes>
  <Route  path="/About" element={<About></About>}></Route>
</Routes>
<Routes>
  <Route  path="/Price" element={<Price></Price>}></Route>
</Routes>
<Routes>
  <Route  path="/Works" element={<Works></Works>}></Route>
</Routes>
<Routes>
  <Route  path="/Footer" element={<Footer></Footer>}></Route>
</Routes>
<Routes>
  <Route path="/Zenezena" element={<Zenezena></Zenezena>}></Route>
</Routes>
<Routes>
   <Route path='/Bike' element={<Bike></Bike>}></Route>
</Routes>
{/* <Routes> 
  <Route  path= '/Singlepost' element={<SinglePost></SinglePost>}></Route>
</Routes> */}
<Routes> 
  <Route  path= '/Works' element={<Works></Works>}></Route>
</Routes>

<Routes> 
  <Route  path= '/Qasil' element={<Qasil></Qasil>}></Route>
</Routes>
<Routes>
  <Route path='/Adey' element={<Adey></Adey>}></Route>
</Routes>
<Routes>
  <Route path='/Speaklife' element={<Speaklife></Speaklife>}></Route>
</Routes>
<Routes>
  <Route path='/Caffe' element={<Caffe></Caffe>}></Route>
</Routes>
<Routes>
  <Route path='/Breez' element={<Breez></Breez>}></Route>
</Routes>
<Routes>
  <Route path='/Food' element={<Food></Food>}></Route>
</Routes>
<Routes>
  <Route path='/Eloan' element={<Eloan></Eloan>}></Route>
</Routes>
<Routes>
  <Route path='/MailchimpForm' element={<MailchimpForm></MailchimpForm>}></Route>
</Routes>
  <Routes>
  <Route path='/Contactmain' element={<Contactmain></Contactmain>}></Route>
</Routes>
<Routes>
  <Route path='/Shop' element={<Shop></Shop>}></Route>
</Routes>
<Routes>
  <Route path='/Ikram' element={<Ikram></Ikram>}></Route>
</Routes>
{/* <Routes>
  <Route path='/ProductShop' element={<Comingsoon></Comingsoon>}></Route>
</Routes> */}
<Routes>
  <Route path='/ProductShop' element={<Shop></Shop>}></Route>
  </Routes>

<Routes>
  <Route path='/Yenacon' element={<Yenacon></Yenacon>}></Route>
</Routes>
<Routes>
  <Route path='/Hayloga' element={<Hayloga></Hayloga>}></Route>
</Routes>
<Routes>
  <Route path='/Poster' element={<Poster></Poster>}></Route>
</Routes>
<Routes>
  <Route path='/Caffeinn' element={<Caffeinn_detail></Caffeinn_detail>}></Route>
</Routes>
</Router>
     
    </div>
  );
}

export default App;
