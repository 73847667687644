import React from "react";
import { NavBar } from "./NavBar";
import headerImg from "../assets/img/frame.jpg";
import { Footer } from "./Footer";
import image from "../assets/img/about.jpg"
import { Center } from "@chakra-ui/react";
import "../App.css";
import "../index.css";
import reviews from "./Testimonal/data";
import Testimonials from "./Testimonal/Testimonials";
import { Container, Row, Col } from "react-bootstrap";
export const About = () => {
  return (
    <>
        <Container>
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
          
            <h2 className="bannertitile">THIS IS PERSPECTIVE</h2>
            <br></br>
            <h6 className="aboutdescription">Our team comprises three talented university graduates, all  <span class='highlight'>WOMEN</span> , with expertise in website development, UI/UX design, graphics design, and social media management. Since starting on April 1, 2022, we've collaborated with various companies, refining our skills through diverse projects.</h6>
            
            <h6 className="aboutdescription"> We <span class='highlight'>prioritize</span>  user experience and visual appeal, ensuring our websites and software interfaces are intuitive and engaging. With a focus on creativity and attention to detail, we exceed client expectations, delivering cutting-edge designs that resonate with audiences.</h6>
         
            <br></br><br></br>
            {/* <button className="but" onClick={SeeMore}>Work With US </button> */}
          </Col>
   
          <Col xs={12} md={6} xl={5}>
          
            
                {/* <div className={isVisible ? "animate__animated animate__zoomIn" : ""}> */}
                   <img src={headerImg} alt="Header Img"/>
               
                
                   <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
          </Col>
        </Row>
        
        
      </Container>
      <div className="flex flex-col w-[100vw] h-[100vh] justify-center items-center bg-black-200">
     
     <div className="text-center">
       
       <h1 className="text-4xl font-bold"> clients pov... </h1>
       
       <div className="bg-white-400 h-[4px] w-1/5 mt-1 mx-auto"></div>
       
       <Testimonials reviews={reviews}/>

     </div>
   </div>
      <Footer></Footer>
    </>
  )
}
