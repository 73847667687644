import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Container from '@mui/material/Container';
import z1 from '../assets/img/michot/banner.jpg'
import grbg from "../assets/img/michot/gren bg.jpg"
import whbg from "../assets/img/michot/white bg .jpg";
import color from "../assets/img/michot/color.jpg";
import typo from "../assets/img/michot/typography.jpg";
import banner2 from '../assets/img/michot/banner2.jpg';
import banner3 from '../assets/img/michot/banner 3.jpg';
// import mock1 from "../assets/img/michot/mock1.jpg";
import mock3 from "../assets/img/michot/mock3.jpg";
import { NavBar } from './NavBar';
import { Footer } from './Footer';
export const Speaklife = () => {
// export default function Speaklife () {
  return (
    <div>
    <NavBar></NavBar>
    <Container maxWidth="lg">
   <div className='speaklife'>
    <img src={z1} />   
    <br></br>  <br></br>  <br></br>
    <p className="singlePostDesc">
    Welcome to the project portfolio overview of Michot, an interior shop that aims to provide comfort 
    and fulfill dreams through exceptional interior design. Our motto, "Where Dreams Come Home," reflects
     our commitment to creating spaces that not only meet our clients' needs but also exceed their expectations.
      This portfolio will showcase some of our notable projects, highlighting our expertise, design philosophy,
       and ability to transform spaces into dream homes.
          {/* <br/>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste error
          quibusdam ipsa quis quidem doloribus eos, dolore ea iusto impedit!
          Voluptatum necessitatibus eum beatae, adipisci voluptas a odit modi
          eos! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste
          error quibusdam ipsa quis quidem doloribus eos, dolore ea iusto
          impedit! Voluptatum necessitatibus eum beatae, adipisci voluptas a
          odit modi eos! Lorem, ipsum dolor sit amet consectetur adipisicing */}
        </p>
    <br></br> 
    <MDBRow>
      <MDBCol lg={6} md={12} className='mb-4 mb-lg-0'>
        <img
          src={whbg}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />

        <img
          src={typo}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Wintry Mountain Landscape'
        />
      </MDBCol>

      <MDBCol lg={6} className='mb-4 mb-lg-0'>
        <img
          src={color}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Mountains in the Clouds'
        />

        <img
          src={grbg}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
      </MDBCol>
      <img
          src={banner3}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />

        <MDBRow>
          <MDBCol>
          <img
          src={mock3}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
  </MDBCol>
  <MDBCol>
<img
          src={banner2}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />

          </MDBCol>
        
        </MDBRow>

    </MDBRow>
    </div>
    </Container>
    <Footer></Footer>
    </div>
  );
}