import { AlertDescription } from "@chakra-ui/react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../App.css"
export const ProjectCard = ({ title, description, imgUrl, href }) => {
  return (
    <Col size={12} sm={6} md={4} className="allcol">
      <div className="proj-imgbx">
        <img src={imgUrl} /> 
        <div className="proj-txtx">
        <h4>{title}</h4>
        <span>{description}</span>
        <br></br> <br></br>
         {/* <a className="herebtn" href={href}>here</a> */}

        </div>
        
      </div>
  
  
    </Col>
  )
}
