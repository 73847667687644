import React, { useState, useEffect } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import AwesomeSliderStyles from '../components/open-animation.scss';
import img1 from '../assets/img//caffeinn_cover.jpg';
import img4 from '../assets/img/abenezer.jpg';
import img5 from '../assets/img/alela bg.jpg';
import img2 from '../assets/img//Shop/qasil banner main.jpg';
import img3 from '../assets/img//Bike/Frame (3).png';

const ShopSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoplay, setIsAutoplay] = useState(true);

  useEffect(() => {
    if (isAutoplay) {
      const timeoutId = setTimeout(() => {
        const nextIndex = (currentIndex + 1) % 3
        setCurrentIndex(nextIndex);
      }, 3000); 

      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex, isAutoplay]);

  return (
    <AwesomeSlider
      cssModule={AwesomeSliderStyles}
      className="custom-slider"
      infinite={true}
      selected={currentIndex}
    >
      {/* Slide 1 */}
      <div data-src={img1} className="slide-content">
        
        {/* <a href="/caffeinn" className="slide-link">View Template</a> */}
      </div>
      
      {/* Slide 2 */}
      <div data-src={img4} className="slide-content">
        {/* <a href="/path-to-page2" className="slide-link">View Template</a> */}
      </div>
      
      {/* Slide 3 */}
      {/* <div data-src={img5} className="slide-content">
        <a href="/path-to-page3" className="slide-link">View Template</a>
      </div> */}
    </AwesomeSlider>
  );
};

export default ShopSlider;
