import React from 'react';
import './welcome.css';
function Draft() {
   return (
     <>
      <div class='wrap'>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
        <div class='tri'></div>
      </div>
     </>
   );
 }
 
 export default Draft;