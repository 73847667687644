import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ze1 from '../assets/img/ze/zenezena banner.jpg'
import ze2 from '../assets/img/ze/coffee 1.png'
import ze3 from '../assets/img/ze/zemo.JPG'
import ze4 from '../assets/img/ze/coffee5.png'
import ze5 from '../assets/img/ze/zemo4.png'
import ze6 from '../assets/img/ze/zemo5.png'
import ze7 from '../assets/img/ze/ze11.JPG'
import ze41 from '../assets/img/ze/zemo41.png';
import ze51 from '../assets/img/ze/zemo51.png';
import ze8 from '../assets/img/ze/Frame 3 (2).png'
// import { NavBar } from './NavBar';
import { NavBar } from "./NavBar";
import { Footer } from './Footer'
export const Zenezena = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <NavBar></NavBar>
      <Container maxWidth="lg">
        <div className='zebanner'>
        <img src={ze1} />
        </div>
        <p className="singlePostDesc">
        zenezena coffee is a website that I designed for a coffee shop. The website is simple and easy to use, with a focus on the coffee shop's products and services. The website also includes a blog section where customers can learn more about the coffee shop and its offerings.
zenezena  coffee is a great example of Our work as a web designer.
          <br/>
        </p>
        <img src={ze2} />
        <div class="roo">
  <div class="col">
    <img src={ze41} alt="Snow" style={{width:"100%"}}></img>
  </div>
  <div class="col">
    <img src={ze51} alt="Forest" style={{width:"100%"}}></img>
  </div>

</div>

{/* <img src={ze7} /> */}
<div>
<img src={ze8} />
</div>

      </Container>
      <Footer></Footer>
    </React.Fragment>
  );
}