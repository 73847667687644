const navbarItems = [
  {
    title: "Home",
    link: "/",
  },
  // {
  //   title: "Services",
  //   link: "/skills",
  // },
  {
    title: "About",
    link: "/About",
  },
];

export default navbarItems;
