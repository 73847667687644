import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Container from '@mui/material/Container';
import c1 from '../assets/img/caffe/Coffee_Cup_Mockup banner.jpg'
import c3 from "../assets/img/caffe/door sign.png"
import c2 from "../assets/img/caffe/cup.jpg"
import c4 from "../assets/img/caffe/menu.jpg"
import c5 from "../assets/img/caffe/mocks.jpg"
import c6 from "../assets/img/caffe/Flyer_with_the_Coffee.jpg"
import c7 from "../assets/img/caffe/photo_2023-10-02_15-09-08.jpg"
import c8 from "../assets/img/caffe/mocks.jpg"
import { NavBar } from './NavBar';
import { Footer } from './Footer';
import { Check2 } from 'react-bootstrap-icons';
export const Caffe = () => {

  return (
    <div>
    <NavBar></NavBar>
    <Container maxWidth="lg">
   <div className='speaklife'>
    <img src={c1} />   
    <p className="singlePostDesc">
    We had the pleasure of designing a captivating branding for Caffe'inn, a charming café. By focusing on creating a unique and inviting visual identity, 
    We successfully captured the cozy atmosphere and high-quality offerings of the café. 
    Through the use of warm colors, elegant typography, We created a brand that truly reflects Caffe'inn's commitment to providing a delightful coffee experience. This project showcases our ability to design compelling visuals that effectively communicate a brand's personality and values.
        </p>
    <br></br>
    <MDBRow>
        
      <MDBCol lg={6}  md={12} className='mb-4 mb-lg-0'>
        <img
          src={c2}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Mountains in the Clouds'
        />

        <img
          src={c3}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
      
      </MDBCol>
      <MDBCol lg={6} className='mb-4 mb-lg-0'>
           <img
          src={c4}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
        <img
          src={c8}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
         
        
      </MDBCol>

      

<MDBRow>
          <MDBCol>
          <img
          src={c6}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Wintry Mountain Landscape'
        />
  </MDBCol>
  <MDBCol>
  <img
          src={c7}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />

          </MDBCol>
        
        </MDBRow>
    </MDBRow>
    </div>
    </Container>
    <Footer></Footer>
    </div>
  );
}