import Container from '@mui/material/Container';
import sh1 from '../assets/img/Shop/Frame 1 (6).png';
import sh2 from '../assets/img/Shop/abenezer.png';
import sh3 from '../assets/img/Shop/caffeinn.png';
import sh4 from '../assets/img/Shop/alela.jpg';
import Timer from './Countdown/Timer'
// import { NavBar } from './NavBar';
import { Navigate, useNavigate } from "react-router-dom";
import { Footer } from './Footer'
import ShopSlider from './ShopSlider';

export const Shop = () => {
let navigate = useNavigate()
const SeeMore = () =>{
  let path= '/contact'; 
  navigate(path)
}
    return(
    <div>
  
    
   <div className='sban'>
    <Container maxWidth="lg">
     
        <div className='sban'>
        {/* <Timer></Timer> */}
        <ShopSlider/>
      
     
        <div class="container my-5">
  <div class="row">
    <div class="col-lg-6 shopimage">
      <img class="w-100 shadow" src={sh2} />
    </div>
    <div class="col-lg-6">
      <div class="p-0 mt-4 showtext">
          <p>
          Introducing our photography website solution designed specifically for photographers. This product allows you to easily showcase your portfolio and manage your content with ease. Featuring a user-friendly backend, you can effortlessly change and control your website, ensuring your work always looks stunning and professional.
          </p>
          <h2></h2>
        
          <button className="but" onClick={SeeMore}> Shop Now </button>
        </div>
    </div>
</div>
</div>

<div class="container my-5">
  <div class="row">
  <div class="col-lg-6 shopimage">
      <img class="w-100 shadow" src={sh3} />
    </div>
    <div class="col-lg-6">

      <div class="p-0 mt-4 showtext">
          <p > 
          Introducing our café website solution designed specifically for cafés. This product allows you to easily showcase your menu, ambiance, and special offers. Featuring a user-friendly backend, you can effortlessly change and control your website, ensuring your café always looks inviting and professional.  </p>
       
            <button className="but" onClick={SeeMore}> Shop Now </button>
        </div>
    </div>
   
</div>
</div>
<div class="container my-5">
  <div class="row">
    <div class="col-lg-6 shopimage">
      <img class="w-100 shadow" src={sh4} />
    </div>
    <div class="col-lg-6">
      <div class="p-0 mt-4 showtext">
          <p > 
          Introducing our company profile website solution designed specifically for businesses. This product allows you to easily showcase your company's services, achievements, and team. Featuring a user-friendly backend, you can effortlessly change and control your website, ensuring your company always looks professional and engaging.  </p>
         
          <button className="but" onClick={SeeMore}> Shop Now </button>
        </div>
    </div>
</div>
</div>
</div>
        </Container>
        </div>
        
        <Footer></Footer>
        </div>

    )
}