import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Container from '@mui/material/Container';
import z1 from '../assets/img/qasil/qasil banner main.jpg'
import grbg from "../assets/img/qasil/qasil white-01.jpg"
import leaf from "../assets/img/qasil/leaf.jpg"
import whbg from "../assets/img/qasil/greenqasil-01.jpg";
import color from "../assets/img/qasil/qasil color.jpg";
import mock from "../assets/img/qasil/m.jpg";
import banner2 from '../assets/img/qasil/mockuppackages.jpg';
import mock1 from '../assets/img/qasil/moc 1.jpg';
// import mock1 from "../assets/img/michot/mock1.jpg";
import mock2 from '../assets/img/qasil/moc 2.jpg';
import mock3 from '../assets/img/qasil/qasil mockup.jpg';
import { NavBar } from './NavBar';
import { Footer } from './Footer';
export const Qasil = () => {
// export default function Speaklife () {
  return (
    <div> 
    <NavBar></NavBar>
    <Container maxWidth="lg">
   <div className='qasil'>
    <img src={z1} />   
    <br></br> <br></br>
    <p className="singlePostDesc">
    Welcome to the project portfolio overview of Qasil Skincare Branding Design, 
    a project dedicated to creating a visually captivating and cohesive brand identity 
    for a revolutionary skincare product that harnesses the power of qasil, a leaf used by 
    Somalis for centuries for its skin-nourishing properties. Our mission is to develop 
    a branding design that effectively communicates the natural and effective skincare solutions
     inspired by traditional Somali beauty rituals. This portfolio will highlight the unique benefits
      of qasil, showcase our branding design concepts, and demonstrate our commitment to promoting healthy and radiant skin.

          
        </p>
    <br></br>
    <MDBRow>
      <MDBCol lg={6} md={12} className='mb-4 mb-lg-0'>
        <img
          src={leaf}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />

        <img
          src={banner2}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Wintry Mountain Landscape'
        />
      </MDBCol>

      <MDBCol lg={6} className='mb-4 mb-lg-0'>
        <img
          src={color}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Mountains in the Clouds'
        />

        <img
          src={whbg}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
      </MDBCol>
      <img
          src={mock3}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />

        <MDBRow>
          <MDBCol>
          <img
          src={mock2}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
  </MDBCol>
  <MDBCol>
<img
          src={mock}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />

          </MDBCol>
        
        </MDBRow>

    </MDBRow>
    </div>
    </Container>
    <Footer></Footer>
    </div>
  );
}
