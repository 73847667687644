import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ik1 from '../assets/img/Bike/Frame 1.png';
import ik2 from '../assets/img/Bike/Frame 3.png'
import ze3 from '../assets/img/ze/zemo.JPG'
import ze4 from '../assets/img/ze/coffee5.png'
import ik5 from '../assets/img/Bike/Frame (2).png'
import ik6 from '../assets/img/Bike/Frame (3).png'
import ze7 from '../assets/img/ze/ze11.JPG'
import ze8 from '../assets/img/ze/Frame 3 (2).png'
import { NavBar } from "./NavBar";
import { Footer } from './Footer'
export const Bike = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <NavBar></NavBar>
      <Container maxWidth="lg">
        <div className='zebanner'>
        <img src={ik1} />
        </div>
        <p className="singlePostDesc">
        BIKE LUXURY  Experience the epitome of elegance and comfort with our Bike Luxury UI/UX design. Elevate your cycling experience like never before.
          <br/>
        </p>
      
        <div class="ro">
  <div class="co">
    <img src={ik5} alt="Snow" style={{width:"100%"}}></img>
  </div>
  <div class="co">
    <img src={ik6} alt="Forest" style={{width:"100%"}}></img>
  </div>

</div>



        <img src={ik2} />
  
{/* <img src={ze7} /> */}
<div>

</div>

      </Container>
      <Footer></Footer>
    </React.Fragment>
  );
}