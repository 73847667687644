import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import adey from "../assets/img/adey/banner final.jpg";
import adeyboth from "../assets/img/adey/both.jpg"
import adeymockup from "../assets/img/adey/adey mockup.jpg"
import adeywhite from "../assets/img/adey/adey white mockup.jpg"
//import adeybusiness from "../assets/img/adey/business mockup.png"
import adeylogo from "../assets/img/adey/Adey logo.png";
import adeywhitelogo from "../assets/img/adey/adey logo whi.png"
import adeyshop from "../assets/img/adey/shop.jpg"
import adeyshop2 from "../assets/img/adey/shop2.jpg"
import adeycolor from "../assets/img/adey/color.jpg"
import adeymock from "../assets/img/adey/mockbanner.jpg"
import { NavBar } from "./NavBar";
import { Footer } from './Footer'

export const Adey = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <NavBar></NavBar>
      <Container maxWidth="lg">
        <div className='zebanner'>
        <img src={adey} />
        </div>
        <br></br>  <br></br> 
        <p className="singlePostDesc">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste error
          quibusdam ipsa quis quidem doloribus eos, dolore ea iusto impedit!
          Voluptatum necessitatibus eum beatae, adipisci voluptas a odit modi
          eos! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste
          error quibusdam ipsa quis quidem doloribus eos, dolore ea iusto
          impedit! Voluptatum necessitatibus eum beatae, adipisci voluptas a
          odit modi eos! Lorem, ipsum dolor sit amet consectetur adipisicing
          <br/>
        </p>
      
        <div class="aro">
  <div class="aco">
    <img src={adeyshop} alt="Snow" style={{width:"100%"}}></img>
  </div>
 
  <div class="aco">
    <img src={adeyshop2} alt="Forest" style={{width:"100%"}}></img>
  </div>

</div>

<br></br>  <br></br> <br></br>  

        <img src={adeycolor} />
          
        <div class="aro">
 

</div>
<br></br>  <br></br> <br></br> 
<img src={adeymock} />

<div>

</div>

      </Container>
      <br></br>  
      <Footer></Footer>
    </React.Fragment>
  );
}