import React from 'react';
import { Box, Image, Text, Icon } from '@chakra-ui/react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const Card = (props) => {
    let review = props.review;
    return (
        <Box 
            d="flex" 
            flexDirection="column" 
            alignItems="center" 
            position="relative" 
            w={{ base: '20em', md: '25em', lg: '30em' }} // Adjust width based on screen size
            bg="#ababab" 
            p="1rem" 
            borderRadius="1rem" 
            m="0.5rem"
        >
            <Box 
                position="absolute" 
                top="-3rem" 
                left="50%"
                transform="translateX(-50%)"
                zIndex="20"
                d="flex"
                justifyContent="center"
            >
                <Image
                    borderRadius="8em"
                    boxSize="100px"
                    src={review.image}
                    alt={review.name}
                    objectFit="cover"
                />
                <Box 
                    position="absolute" 
                    w="100px" 
                    h="100px" 
                    top="0" 
                    left="0" 
                    zIndex="-1" 
                    borderRadius="full"
                    bg="transparent"
                ></Box>
            </Box>

            <Box textAlign="center" mt="5"  margin="2rem" >
                <Text fontWeight="bold" textTransform="capitalize" fontSize="lg" color="black" >
                    {review.name}
                </Text>
                <Text textTransform="uppercase" fontSize="sm" color="white">
                    {review.job}
                </Text>
            </Box>

            <Box mx="auto" mt="2" color="#ff389f">
                <Icon as={FaQuoteLeft} />
            </Box>

            <Box textAlign="center" mt="2" color="black">
                {review.text}
            </Box>

            <Box mx="auto" mt="2" color="#ff389f">
                <Icon as={FaQuoteRight} />
            </Box>
        </Box>
    );
}

export default Card;
