import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Container from '@mui/material/Container';
import banner from '../assets/img/breez/BREEZ POSTER.png'
import color from '../assets/img/breez/color.png'
import mock1 from '../assets/img/breez/logomock.jpg'
import both from '../assets/img/breez/breezlogo.png'

import { NavBar } from './NavBar';
import { Footer } from './Footer';
export const Breez = () => {
// export default function Speaklife () {
  return (
    <div>
    <NavBar></NavBar>
    <Container maxWidth="lg">
   <div className='qasil'>
    <img src={banner} />   
    <p className="singlePostDesc">
    The coffee branding design for Breez Coffee embodies the essence of sophistication and relaxation. 
    Inspired by the gentle flow of an ocean breeze, the logo features a sleek and minimalist design that captures the brand's commitment to delivering a refreshing coffee experience.
     The color palette, evokes a sense of calmness and tranquility. 
     From the elegant packaging to the meticulously crafted typography, every aspect of the branding design reflects Breeze Coffee's dedication to quality and elegance.
        </p>  
        <MDBRow>
          <MDBCol>
          <img
          src={color}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
  </MDBCol>
  <MDBCol>
<img
          src={mock1}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />

          </MDBCol>
        
        </MDBRow>

  
   <MDBRow>
   <img
          src={both}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
   </MDBRow>
    </div>
    </Container>
    <Footer></Footer>
    </div>
  );
}