import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import ui1 from "../assets/img/Bike/Frame 1.png";
import ui2 from "../assets/img/ui/ebidirr (2).png";
import ui3 from "../assets/img/ui/ik banner.jpg";
import ye1 from "../assets/img/ye/Frame 1 (5).png";
import Po1 from "../assets/img/poster/3.png"
import Haylog from "../assets/img/hayloga/hero.jpg";
import food from "../assets/img/ui/ethio foodd.png";
import Zenezena from "../assets/img/ze/zenezena banner.jpg"
import adey from "../assets/img/adey/banner final.jpg"
import Breez from "../assets/img/breez/BREEZ POSTER.png"
import caffe from "../assets/img/caffe/Coffee_Cup_Mockup banner.jpg"
import Qasil from "../assets/img/qasil/qasil banner main.jpg";
import Michot from "../assets/img/michot/banner.jpg"
import waliifcons from "../assets/img/waliif construction.jpg"
import breez from "../assets/img/breez.png"
import colorSharp2 from "../assets/img/color-sharp2.png";
import four from "../assets/img/ui/Cover.jpg";
import Bespo from "../assets/img/ui/Frame 41.jpg";
import eskill from "../assets/img/ui/Frame 40 (1).jpg";
import furniture from "../assets/img/ui/photo_2024-04-16_16-47-09.jpg"
import DekDek from "../assets/img/ui/DekDek.png";
import kibirt from "../assets/img/kibirt.JPG"
import caffee from "../assets/img/caffe/caffee.png"
import mall from "../assets/img/mall.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Works } from "./Works";
import { Navigate, useNavigate } from "react-router-dom";

export const Projects = () => {
  let navigate = useNavigate()
  const SeeMore = () =>{
    let path= '/Works'; 
    navigate(path)
  }
 

  const projects = [    
    {
      title: "Hayloga Website",
      description: "Website",
      imgUrl: Haylog,
      href: "/Hayloga",
    },
    // {
    //   title: "Ethio Food",
    //   description: "Website",
    //   imgUrl:food,
    //   href: "/Food",
    // },
    {
      title: "ዘነዘና Coffee",
      description: "Website",
      imgUrl: Zenezena,
      href: "/Zenezena",
    },
    {
      title: "kibirt Bag Shop",
      description: "Website",
      imgUrl:   kibirt,
   
    },
  
   
  ]; 
  const projects1 = [
   
    {
      title: "4 Second",
      description: "UI/UX",
      imgUrl: four,
      
    },
    {
      title: "Bespo Engineering",
      description: "UI/UX",
      imgUrl: Bespo,
      
    },
    {
      title: "E-Skill ",
      description: "UI/UX",
      imgUrl: eskill,
      
    },
    {
      title: "Yena Construction",
      description: "UI/UX design",
      imgUrl: ye1,
      href: "/Yenacon",
    },
    {
      title: "Ikram Automotive",
      description: "UI/UX design",
      imgUrl: ui3,
      href: "/ikram",
    },
    {
       title: "Bike Luxury",
      description: "UI/UX design",
      imgUrl:ui1,
      href: "/Bike",
       
    },
    {
      title: "Furniture",
     description: "UI/UX design",
     imgUrl:furniture,

      
   },
   
    
  ];
  const projects3 = [
    // {
    //   title: "Poster & Social Midea Design",
    //   description: "Graphics Design",
    //   imgUrl: Po1,
    //   href: "/Poster",
    //   },
    {
    title: "Waliif Construction Annual Report",
    description: "Graphics Design",
    imgUrl: waliifcons,
    href: "/walif",
    },
     
    {
    title: "Adey Fashion",
    description: "Graphics Design",
    imgUrl:adey ,
    href: "/adey",
    },
    {
      title: "The mall Fashion series",
      description: "Graphics Design",
      imgUrl:mall ,
  
      },
      {
        title: "Breez Coffee",
        description: "Graphics Design",
        imgUrl:breez ,
    
        },
      
    {
      title: "DekDek",
      description: "Graphics Design",
      imgUrl: DekDek ,
  
    },
    {
        title: "Caffe'inn",
        description: "Graphics Design",
        imgUrl: caffee ,
    
    },
         
    {
      title: "Qasil ",
      description: "Branding",
      imgUrl: Qasil ,
  
  },
  ];
  return (
    <section className="project" id="project">
      <Container className="allproject">
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Recent Works</h2>
             
                <Tab.Container id="projects-tabs" defaultActiveKey="second">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                  <Nav.Item>
                      <Nav.Link eventKey="second">UI/UX Design</Nav.Link>
                    </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="first">Web Development</Nav.Link>
                    </Nav.Item>
                   
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">BRANDING </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>     
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projects1.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>     
                      </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          projects3.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>     
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                      <Row>
                        {
                          projects3.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>     
                      </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
                {/* <button className="but" id="middle" onClick={SeeMore}>See More</button> */}
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className="background-image-right" src={colorSharp2}></img> */}
    </section>
    
  )
}
